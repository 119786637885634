<template>
    <b-modal :ref="modalRef" hide-footer @hidden="destroyModal" title="Emprunt" size="lg">
        <div class="text-center">
            <b-col lg="8" offset-lg="2">
                <label>Début</label>
                <date-time-picker v-model="form.dateDebut"/>

                <label class="mt-2">Fin</label>
                <date-time-picker v-model="form.dateFin" :min-datetime="form.dateDebut"/>

                <b-form-select v-if="isBureau()" v-model="form.statut" :options="statutOptions" class="mt-3"></b-form-select>
            </b-col>
            <b-col class="text-left" lg="10" offset-lg="1">
                <hr/>
                <label>Commentaire utilisateur</label>
                <balloon-editor v-model="form.commentaireUtilisateur"/>
                <hr/>
            </b-col>

            <b-button @click="editEmprunt" variant="success">Valider</b-button>
            <b-button @click="hideModal" variant="secondary">Annuler</b-button>
        </div>
    </b-modal>
</template>

<script>
    const BalloonEditor  = () => import('@/components/BalloonEditor');
    const DateTimePicker = () => import('@/components/DateTimePicker');
    const UserName       = () => import('@/components/UserName');

    import demande          from '@/util/demande';
    import modalMixin       from '@/mixin/modalMixin';
    import {apiPath}        from '@/util/http';
    import {isBureau}       from '@/util/auth';
    import {STATUT_OPTIONS} from '@/util/emprunt';

    export default {
        name: "EditEmpruntModal",
        components: {BalloonEditor, DateTimePicker, UserName},
        mixins: [modalMixin],
        props: {
            emprunt: {
                type: Object,
                required: true
            },
            callback: {
                type: Function,
                required: true
            }
        },
        data: () => ({
            modalRef: 'displayEmpruntModal',
            form: {
                dateDebut: null,
                dateFin: null,
                statut: 0,
                commentaireUtilisateur: null
            },
            statutOptions: STATUT_OPTIONS
        }),
        methods: {
            isBureau,
            ...demande,
            editEmprunt() {
                this.axios
                    .post(apiPath('edit_emprunt', {emprunt: this.emprunt.id}), this.form)
                    .then(() => {
                        this.$toaster.success('Emprunt modifié avec succès');
                        this.callback();
                        this.hideModal();
                    })
                    .catch(() => this.$toaster.error("Erreur lors de la modification de l'emprunt"));
            }
        },
        mounted() {
            this.form.commentaireUtilisateur = this.emprunt.commentaireUtilisateur;
            this.form.dateDebut              = this.emprunt.dateDebut.toISOString(true);
            this.form.dateFin                = this.emprunt.dateFin.toISOString(true);
            this.form.statut                 = this.emprunt.statut;
        }
    };
</script>

<style scoped>
    table {
        width: 100%;
    }

    td {
        padding-bottom: 0.5rem;
    }

    td.text-right {
        width: 33%;
    }

    td.text-left {
        padding-left: 10px;
    }
</style>